import React, {useState} from 'react'
import {FaBars, FaTimes, FaGithub, FaLinkedin} from 'react-icons/fa'
import {HiOutlineMail} from 'react-icons/hi'
import {BsFillPersonLinesFill} from 'react-icons/bs'
import {Link} from 'react-scroll'
import {Document} from 'react-pdf'
import resume from '../assets/Resume.pdf';

const Navbar = () => {
    const [nav, setNav] = useState(false);
    const handleClick = () => setNav(!nav);

  return (
    <div className="fixed w-full h-[80px] flex justify-between items-center px-4 bg-[#2F4858] text-gray-300">

        {/* menu */}
        <ul className="hidden md:flex">
            <li>
                <Link to="home" smooth={true}  duration={500} >
                    Home
                </Link>
            </li>
            <li>
                <Link to="about" smooth={true} duration={500} >
                    About
                </Link>
            </li>
            <li>
                <Link to="skills" smooth={true} duration={500} >
                    Skills
                </Link>
            </li>
            <li>
                <Link to="work" smooth={true} duration={500} >
                    Projects
                </Link>
            </li>
            <li>
                <Link to="contact" smooth={true} duration={500} >
                    Contact
                </Link>
            </li>
        </ul>

        <div onClick={handleClick} className="md:hidden z-10">
            {!nav ? <FaBars/> : <FaTimes/>}
        </div>

        {/* mobile menu */}
        <ul className={!nav ? 'hidden' : "absolute top-0 left-0 w-full h-screen bg-[#0089BA] flex flex-col justify-center items-center"}>
            <li className="py-6 text-4xl">
                <Link onClick={handleClick} to="home" smooth={true}  duration={500} >
                    Home
                </Link>
            </li>
            <li className="py-6 text-4xl">
                <Link onClick={handleClick} to="about" smooth={true} duration={500} >
                    About
                </Link>
            </li>
            <li className="py-6 text-4xl">
                <Link onClick={handleClick} to="skills" smooth={true} duration={500} >
                    Skills
                </Link>
            </li>
            <li className="py-6 text-4xl">
                <Link onClick={handleClick} to="work" smooth={true} duration={500} >
                    Projects
                </Link>
            </li>
            <li className="py-6 text-4xl">
                <Link onClick={handleClick} to="contact" smooth={true} duration={500} >
                    Contact
                </Link>
            </li>
        </ul>

        {/* Social icons */}
        <div className="hidden lg:flex fixed flex-col top-[35%] left-0">
            <ul>
                <li className="w-[160px] h-[60px] flex justify justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-blue-600">
                    <a className="flex justify-between items-center w-full text-gray-300" href="https://www.linkedin.com/in/kyle-blanco-0b8128194/">
                        LinkedIn <FaLinkedin size={30}/>
                    </a>
                </li>
                <li className="w-[160px] h-[60px] flex justify justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-gray-700">
                    <a className="flex justify-between items-center w-full text-gray-300" href="https://github.com/kblanco2000?tab=repositories">
                        GitHub <FaGithub size={30}/>
                    </a>
                </li>
                {/* <li className="w-[160px] h-[60px] flex justify justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-red-500">
                    <a className="flex justify-between items-center w-full text-gray-300" href="/">
                        Email <HiOutlineMail size={30}/>
                    </a>
                </li> */}
                <li className="w-[160px] h-[60px] flex justify justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-green-800">
                    <a className="flex justify-between items-center w-full text-gray-300" href="/Users/kyleblanco/Desktop/portfolio-react-app/src/assets/Resume.pdf" download>
                        Resume <BsFillPersonLinesFill size={30}/>
                    </a>
                </li>
                
            </ul>
        </div>

    </div>
  )
}

export default Navbar